import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import { domain, botUrl } from "../../config/ajax";
import * as qs from "query-string";
import axios from "axios";
import { mainPage } from "../../constants/event";
import copy from "copy-to-clipboard";

class Home extends React.Component {
  constructor(props) {
    super(props);
    const get = qs.parse(this.props.location.search);
    if (get.filter) {
      sessionStorage.setItem("openFilter", "1");
      window.location.assign("/filter");
    }
    this.state = {
      newsLoad: false,
      copied: false,
    };
    this.copied = "Скопировать";
    this.news = [];
    this.news = [];
    fetch(`${domain}/api/news`, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.news = result;
          console.log(this.news, 31);
          this.setState({ newsLoad: true });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  componentDidMount = () => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});

    const isTracked = sessionStorage.getItem("HomePage");
    const body = {
      ...mainPage,
      user_id: sessionStorage.getItem("user_id"),
    };
    if (!isTracked) {
      axios
        .post(`${botUrl}/api/ga/cabinet`, body)
        .then((result) => {
          sessionStorage.setItem("HomePage", true);
        })
        .catch((err) => console.log(err));
    }
  };

  shouldComponentUpdate(nextProps) {
    const { pathname } = this.props.location;
    return nextProps.location.pathname !== pathname;
  }

  componentDidUpdate = () => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  };

  render() {
    console.log(this.state.newsLoad, 69);
    const { pathname } = this.props.location;
    return (
      <div key={pathname} className="w-full pt-20">
        <Header logo={true} name="" navigation={this.props} />
        {this.state.copied ? (
          <div className="alert alert-primary" role="alert">
            Скопировано
          </div>
        ) : null}
        <div className="w-full px-6 pb-6 border-b-2 border-gray-300">
          <div className="w-full text-left font-bold text-sm text-gray-700 py-3">
            <div>Мой кабинет</div>
            <div>
              Ваш id: 01{sessionStorage.getItem("user_id")}
              <span
                className="py-1 px-2 ml-5 mt-10 mb-20 text-center text-white rounded shadow-lg bg-blue200o"
                onClick={() => {
                  copy("01" + sessionStorage.getItem("user_id"));
                  this.setState({ copied: true });
                  console.log(this.state.copied);
                  alert("Ваш id скопирован");
                }}
              >
                <i className="fa fa-copy"></i>
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap">
            <div className="w-1/2 pr-1">
              <Link
                to="/filter"
                className="text-left w-full block bg-white shadow-lg px-3 py-2 flex border border-gray-200 rounded"
              >
                <span
                  className="text-base text-blue-700"
                  style={{ marginTop: "-0.1rem" }}
                >
                  <i className="fa fa-filter"></i>
                </span>
                <span className="text-sm pl-2 text-gray-700">Фильтры</span>
              </Link>
            </div>
            <div className="w-1/2 pl-1">
              <Link
                to="/rate"
                className="text-left w-full block bg-white shadow-lg px-3 py-2 flex border border-gray-200 rounded"
              >
                <span
                  className="text-base text-blue-700"
                  style={{ marginTop: "-0.1rem" }}
                >
                  <i className="fa fa-bars"></i>
                </span>
                <span className="text-sm pl-2 text-gray-700">Тариф</span>
              </Link>
            </div>
            <div className="w-1/2 pr-1 mt-2">
              <Link
                to="/stocks"
                className="text-left w-full block bg-white shadow-lg px-3 py-2 flex border border-gray-200 rounded"
              >
                <span
                  className="text-base text-blue-700"
                  style={{ marginTop: "-0.1rem" }}
                >
                  <i className="fa fa-car"></i>
                </span>
                <span className="text-sm pl-2 text-gray-700">
                  Проверка авто
                </span>
              </Link>
            </div>
            <div className="w-1/2 pl-1 mt-2">
              <Link
                to="/help"
                className="text-left w-full block bg-white shadow-lg px-3 py-2 flex border border-gray-200 rounded"
              >
                <span
                  className="text-base text-blue-700"
                  style={{ marginTop: "-0.1rem" }}
                >
                  <i className="fa fa-question-circle"></i>
                </span>
                <span className="text-sm pl-2 text-gray-700">Помощь</span>
              </Link>
            </div>
          </div>
        </div>
        {this.state.newsLoad && (
          <div className="w-full">
            <div className="w-full border-t border-white"></div>
            <div className="w-full px-6 pb-6">
              <div className="w-full text-left font-bold text-sm text-gray-700 py-3">
                Новости бота
                {this.news.map((item, index) => (
                  <Link
                    to={"/news/" + item._id}
                    new={item}
                    key={index}
                    className="w-full mt-3 border border-gray-200 rounded bg-white shadow-lg p-3 flex block"
                  >
                    <div className="w-16 h-12 overflow-hidden flex-shrink-0">
                      <img
                        src={item.image}
                        alt="photos"
                        className="w-full h-auto min-h-full"
                      />
                    </div>
                    <div className="w-full">
                      <div className="text-xs leading-tight pl-3 text-gray-700 font-medium">
                        {item.name.slice(0, 50)}
                      </div>
                      <div className="text-xxs pl-3 font-normal pt-1 text-gray-500">
                        {item.created_at}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="w-full adSence px-6">
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-6212905903661374"
            data-ad-slot={6274128762}
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </div>
      </div>
    );
  }
}

export default Home;
