// const domain = 'http://carfast.test/';
const domain = "https://api.fastbot.pro";
// const domain = "http://127.0.0.1:8000";
// const domain = "http://164.90.212.8:8001"
// const botUrl = "https://api.fastbot.pro";
// const botUrl = "http://127.0.0.1:8000";
const botUrl = "https://api.fastbot.pro";
const socketUrl = "wss://api.fastbot.pro";
module.exports = {
  domain,
  botUrl,
  socketUrl,
};
