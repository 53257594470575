import React from 'react';
import { Link } from "react-router-dom";
import Header from '../Header';
import domain from '../../config/ajax';

class PlaceHolder extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="w-full pt-20">
        <Header logo={true} name="" navigation={this.props} />
        <p>Вы не авторизованы. Для входа в личный кабинет воспользуйтесь ссылкой из вашего аккаунта t.me/carfastbot</p>
      </div>
    );
  }
}

export default PlaceHolder;
