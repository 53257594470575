import React from 'react';

import * as qs from 'query-string';
import Regions from './Regions';
import Brands from './Brands';
import Models from './Models';

class Filter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter,
      viewRegions: false,
      viewBrands: false,
      viewModels: false,
    };
  }

  componentDidMount() {
    const filter = this.state.filter;
    if (filter.priceStart !== 0) {
      filter.priceStart = String(filter.priceStart).replace(/\s/g, '');
      if (filter.priceStart.length === 4) {
        filter.priceStart = `${filter.priceStart.slice(0, 1)} ${filter.priceStart.slice(1, filter.priceStart.replace(/\s/g, '').length)}`;
      } else if (filter.priceStart.length === 5) {
        filter.priceStart = `${filter.priceStart.slice(0, 2)} ${filter.priceStart.slice(2, filter.priceStart.replace(/\s/g, '').length)}`;
      } else if (filter.priceStart.length === 6) {
        filter.priceStart = `${filter.priceStart.slice(0, 3)} ${filter.priceStart.slice(3, filter.priceStart.replace(/\s/g, '').length)}`;
      } else if (filter.priceStart.length === 7) {
        filter.priceStart = `${filter.priceStart.slice(0, 1)} ${filter.priceStart.slice(1, 4)} ${filter.priceStart.slice(4, filter.priceStart.replace(/\s/g, '').length)}`;
      } else if (filter.priceStart.length === 8) {
        filter.priceStart = `${filter.priceStart.slice(0, 2)} ${filter.priceStart.slice(2, 5)} ${filter.priceStart.slice(5, filter.priceStart.replace(/\s/g, '').length)}`;
      } else if (filter.priceStart.length === 9) {
        filter.priceStart = `${filter.priceStart.slice(0, 3)} ${filter.priceStart.slice(3, 6)} ${filter.priceStart.slice(6, filter.priceStart.replace(/\s/g, '').length)}`;
      } else {}
    }
    if (filter.priceEnd !== 0) {
      filter.priceEnd = String(filter.priceEnd).replace(/\s/g, '');
      if (filter.priceEnd.length === 4) {
        filter.priceEnd = `${filter.priceEnd.slice(0, 1)} ${filter.priceEnd.slice(1, filter.priceEnd.replace(/\s/g, '').length)}`;
      } else if (filter.priceEnd.length === 5) {
        filter.priceEnd = `${filter.priceEnd.slice(0, 2)} ${filter.priceEnd.slice(2, filter.priceEnd.replace(/\s/g, '').length)}`;
      } else if (filter.priceEnd.length === 6) {
        filter.priceEnd = `${filter.priceEnd.slice(0, 3)} ${filter.priceEnd.slice(3, filter.priceEnd.replace(/\s/g, '').length)}`;
      } else if (filter.priceEnd.length === 7) {
        filter.priceEnd = `${filter.priceEnd.slice(0, 1)} ${filter.priceEnd.slice(1, 4)} ${filter.priceEnd.slice(4, filter.priceEnd.replace(/\s/g, '').length)}`;
      } else if (filter.priceEnd.length === 8) {
        filter.priceEnd = `${filter.priceEnd.slice(0, 2)} ${filter.priceEnd.slice(2, 5)} ${filter.priceEnd.slice(5, filter.priceEnd.replace(/\s/g, '').length)}`;
      } else if (filter.priceEnd.length === 9) {
        filter.priceEnd = `${filter.priceEnd.slice(0, 3)} ${filter.priceEnd.slice(3, 6)} ${filter.priceEnd.slice(6, filter.priceEnd.replace(/\s/g, '').length)}`;
      } else {}
    }
    this.setState({filter: filter});
  }

  setCleared(name) {
    const filter = this.state.filter;
    filter.isCleared = null;
    if (name === 'Да') {
      filter.isCleared = true;
    }
    if (name === 'Нет') {
      filter.isCleared = false;
    }
    this.setState({filter: filter});
  }

  setStates(name) {
    const filter = this.state.filter;
    filter.condition = name === 'Не важно' ? '' : name;
    this.setState({filter: filter});
  }

  setBoxes(name) {
    const filter = this.state.filter;
    filter.gearbox = name === 'Не важно' ? '' : name;
    this.setState({filter: filter});
  }

  setPercent(name) {
    const filter = this.state.filter;
    filter.percent = name === 'Да' ? -20 : 0;
    this.setState({filter: filter});
  }

  setYear(e, type) {
    const filter = this.state.filter;
    if (type === 0) {
      if (e.target.value.length < 5) {
        const date = new Date();
        if (e.target.value.length === 4) {
          if (Number(e.target.value) < 1960 || Number(e.target.value) > date.getFullYear()) {
            filter.yearStart = 1960;
          } else {
            filter.yearStart = e.target.value;
          }
        } else {
          filter.yearStart = e.target.value;
        }
      }
    } else {
      if (e.target.value.length < 5) {
        const date = new Date();
        if (e.target.value.length === 4) {
          if (Number(e.target.value) > date.getFullYear() || Number(e.target.value) < Number(filter.yearStart)) {
            filter.yearEnd = date.getFullYear();
          } else {
            filter.yearEnd = e.target.value;
          }
        } else {
          filter.yearEnd = e.target.value;
        }
      }
    }
    this.setState({filter: filter});
  }

  setBlurYear(e, type) {
    const filter = this.state.filter;
    const date = new Date();
    if (type === 0) {
      if (e.target.value.length === 0) {
        filter.yearStart = 1960;
      } else {
        if (Number(e.target.value) > Number(filter.yearEnd)) {
          filter.yearStart = 1960;
        } else {
          filter.yearStart = e.target.value;
        }
      }
    } else {
      if (e.target.value.length === 0) {
        filter.yearEnd = date.getFullYear();
      } else {
        if (Number(e.target.value) < Number(filter.yearStart)) {
          filter.yearEnd = date.getFullYear();
        } else {
          filter.yearEnd = e.target.value;
        }
      }
    }
    this.setState({filter: filter});
  }

  setPrice(e, type) {
    const filter = this.state.filter;
    if (String(e.target.value).match(/[^0-9\s]/g)) {} else {
      if (type === 0) {
        if (String(e.target.value).replace(/\s/g, '').length <= 11) {
          if (e.target.value.length === 0) {
            filter.priceStart = 0;
          } else {
            if (e.target.value.length && (e.target.value[0] === '0')) {
              filter.priceStart = e.target.value.slice(1, e.target.value.length);
            } else {
              filter.priceStart = String(e.target.value).replace(/\s/g, '');
              if (filter.priceStart.length === 4) {
                filter.priceStart = `${filter.priceStart.slice(0, 1)} ${filter.priceStart.slice(1, filter.priceStart.replace(/\s/g, '').length)}`;
              } else if (filter.priceStart.length === 5) {
                filter.priceStart = `${filter.priceStart.slice(0, 2)} ${filter.priceStart.slice(2, filter.priceStart.replace(/\s/g, '').length)}`;
              } else if (filter.priceStart.length === 6) {
                filter.priceStart = `${filter.priceStart.slice(0, 3)} ${filter.priceStart.slice(3, filter.priceStart.replace(/\s/g, '').length)}`;
              } else if (filter.priceStart.length === 7) {
                filter.priceStart = `${filter.priceStart.slice(0, 1)} ${filter.priceStart.slice(1, 4)} ${filter.priceStart.slice(4, filter.priceStart.replace(/\s/g, '').length)}`;
              } else if (filter.priceStart.length === 8) {
                filter.priceStart = `${filter.priceStart.slice(0, 2)} ${filter.priceStart.slice(2, 5)} ${filter.priceStart.slice(5, filter.priceStart.replace(/\s/g, '').length)}`;
              } else if (filter.priceStart.length === 9) {
                filter.priceStart = `${filter.priceStart.slice(0, 3)} ${filter.priceStart.slice(3, 6)} ${filter.priceStart.slice(6, filter.priceStart.replace(/\s/g, '').length)}`;
              } else {}
            }
          }
        }
      } else {
        if (String(e.target.value).replace(/\s/g, '').length <= 11) {
          if (e.target.value.length === 0) {
            filter.priceEnd = 0;
          } else {
            if (e.target.value.length && (e.target.value[0] === '0')) {
              filter.priceEnd = e.target.value.slice(1, e.target.value.length);
            } else {
              filter.priceEnd = String(e.target.value).replace(/\s/g, '');
              if (filter.priceEnd.length === 4) {
                filter.priceEnd = `${filter.priceEnd.slice(0, 1)} ${filter.priceEnd.slice(1, filter.priceEnd.replace(/\s/g, '').length)}`;
              } else if (filter.priceEnd.length === 5) {
                filter.priceEnd = `${filter.priceEnd.slice(0, 2)} ${filter.priceEnd.slice(2, filter.priceEnd.replace(/\s/g, '').length)}`;
              } else if (filter.priceEnd.length === 6) {
                filter.priceEnd = `${filter.priceEnd.slice(0, 3)} ${filter.priceEnd.slice(3, filter.priceEnd.replace(/\s/g, '').length)}`;
              } else if (filter.priceEnd.length === 7) {
                filter.priceEnd = `${filter.priceEnd.slice(0, 1)} ${filter.priceEnd.slice(1, 4)} ${filter.priceEnd.slice(4, filter.priceEnd.replace(/\s/g, '').length)}`;
              } else if (filter.priceEnd.length === 8) {
                filter.priceEnd = `${filter.priceEnd.slice(0, 2)} ${filter.priceEnd.slice(2, 5)} ${filter.priceEnd.slice(5, filter.priceEnd.replace(/\s/g, '').length)}`;
              } else if (filter.priceEnd.length === 9) {
                filter.priceEnd = `${filter.priceEnd.slice(0, 3)} ${filter.priceEnd.slice(3, 6)} ${filter.priceEnd.slice(6, filter.priceEnd.replace(/\s/g, '').length)}`;
              } else {}
            }
          }
        }
      }
    }
    this.setState({filter: filter});
  }

  setBlurPrice(e, type) {
    /*
    const filter = this.state.filter;
    if (type === 0) {
      if (Number(String(filter.priceStart).replace(/\s/g, '')) > Number(String(filter.priceEnd).replace(/\s/g, ''))) {
        filter.priceStart = filter.priceEnd;
      }
    } else {
      if (Number(String(filter.priceEnd).replace(/\s/g, '')) < Number(String(filter.priceStart).replace(/\s/g, ''))) {
        filter.priceEnd = filter.priceStart;
      }
    }
    this.setState({filter: filter});
    */
  }

  setRegion(name) {
    const filter = this.state.filter;
    filter.region = name;
    this.setState({filter: filter, viewRegions: false});
  }

  setCity(data) {
    const filter = this.state.filter;
    filter.city = data.city;
    filter.city_name = data.city_name;
    this.setState({filter: filter, viewRegions: false});
  }

  setBrand(name) {
    const filter = this.state.filter;
    filter.brand = name;
    if(filter.brand.length>1){
      this.setState({filter: filter, viewBrands: false, viewModels:true});
    }
    else{
      this.setState({filter: filter, viewBrands: false, viewModels:false});
    }

  }

  setModel(name) {
    const filter = this.state.filter;
    filter.model = name;
    this.setState({filter: filter, viewModels: false});
  }

  resetRegion() {
    const filter = this.state.filter;
    filter.region = '';
    filter.city = '';
    filter.city_name = '';
    this.setState({filter: filter});
  }

  resetBrand() {
    const filter = this.state.filter;
    filter.brand = '';
    filter.model = '';
    this.setState({filter: filter});
  }

  resetModel() {
    const filter = this.state.filter;
    filter.model = '';
    this.setState({filter: filter});
  }

  save() {
    this.props.setfilter(this.state.filter);
  }

  render() {
    return (
      <div className="w-full">
        {this.state.viewRegions === true &&
          <Regions regions={this.props.regions} select={this.state.filter.region} setregion={this.setRegion.bind(this)} setcity={this.setCity.bind(this)} />
        }
        {this.state.viewBrands === true &&
          <Brands brands={this.props.brands} select={this.state.filter.brand} setbrand={this.setBrand.bind(this)} />
        }
        {this.state.viewModels === true && this.state.filter.brand !== 'Все марки' &&
          <Models brands={this.props.brands} brand={this.state.filter.brand} select={this.state.filter.model} setmodel={this.setModel.bind(this)} />
        }
        {this.state.viewRegions === false && this.state.viewBrands === false && this.state.viewModels === false &&
          <div className="w-full px-6 pb-6">
            <div className="w-full text-left font-bold text-sm text-gray-700 py-3 pt-0">
              Заполните нужные поля
            </div>
            <div className="w-full bg-white rounded shadow-lg border border-gray-200">
              <div className="w-full p-3">
                <div className="w-full text-gray-600 text-xs pt-0 pb-2">
                  Регион
                </div>
                <div className="w-full flex">
                  <div className="w-full border border-gray-300 rounded px-3 py-1 text-gray-800 text-sm rounded-r-none" onClick={() => this.setState({viewRegions: true})}>
                    {this.state.filter.region === '' ? 'Все регионы' : this.state.filter.region}
                  </div>
                  {this.state.filter.region === '' &&
                    <div onClick={() => this.setState({viewRegions: true})} className="flex-shrink-0 border border-gray-300 rounded px-3 py-1 text-blue-600 text-sm rounded-l-none border-l-0">
                      Указать
                    </div>
                  }
                  {this.state.filter.region !== '' &&
                    <div onClick={() => this.resetRegion()} className="flex-shrink-0 border border-gray-300 rounded px-3 py-1 text-red-600 text-sm rounded-l-none border-l-0">
                      Сбросить
                    </div>
                  }
                </div>
                <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                  Марка
                </div>
                <div className="w-full flex">
                  <div className="w-full border border-gray-300 rounded px-3 py-1 text-gray-800 text-sm rounded-r-none" onClick={() => this.setState({viewBrands: true})}>
                    {this.state.filter.brand === '' ? 'Все марки' : this.state.filter.brand}
                  </div>
                  {this.state.filter.brand === '' &&
                    <div onClick={() => this.setState({viewBrands: true})} className="flex-shrink-0 border border-gray-300 rounded px-3 py-1 text-blue-600 text-sm rounded-l-none border-l-0">
                      Указать
                    </div>
                  }
                  {this.state.filter.brand !== '' &&
                    <div onClick={() => this.resetBrand()} className="flex-shrink-0 border border-gray-300 rounded px-3 py-1 text-red-600 text-sm rounded-l-none border-l-0">
                      Сбросить
                    </div>
                  }
                </div>
                {this.state.filter.brand !== '' &&
                  <div className="w-full">
                    <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                      Модель
                    </div>
                    <div className="w-full flex">
                      <div className="w-full border border-gray-300 rounded px-3 py-1 text-gray-800 text-sm rounded-r-none" onClick={() => this.setState({viewModels: true})}>
                        {this.state.filter.model === '' ? 'Все модели' : this.state.filter.model}
                      </div>
                      {this.state.filter.model === '' &&
                        <div onClick={() => this.setState({viewModels: true})} className="flex-shrink-0 border border-gray-300 rounded px-3 py-1 text-blue-600 text-sm rounded-l-none border-l-0">
                          Указать
                        </div>
                      }
                      {this.state.filter.model !== '' &&
                        <div onClick={() => this.resetModel()} className="flex-shrink-0 border border-gray-300 rounded px-3 py-1 text-red-600 text-sm rounded-l-none border-l-0">
                          Сбросить
                        </div>
                      }
                    </div>
                  </div>
                }
                <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                  Цена
                </div>
                <div className="w-full flex">
                  <div className="w-1/2 pr-1">
                    <div className="w-full border border-gray-300 rounded flex flex-row overflow-hidden">
                      <div className="px-2 pt-1 pb-1 text-sm text-gray-600">от</div>
                      <input value={this.state.filter.priceStart} onChange={(e) => this.setPrice(e, 0)} onBlur={(e) => this.setBlurPrice(e, 0)} type="text" maxLength="11" className="block w-full text-xs outline-none" max="100000" />
                    </div>
                  </div>
                  <div className="w-1/2 pl-1">
                    <div className="w-full border border-gray-300 rounded flex overflow-hidden">
                      <div className="px-2 pt-1 pb-1 text-sm text-gray-600">до</div>
                      <input value={this.state.filter.priceEnd} onChange={(e) => this.setPrice(e, 1)} onBlur={(e) => this.setBlurPrice(e, 1)} type="text" maxLength="11" className="block w-full text-xs outline-none" max="100000" />
                    </div>
                  </div>
                </div>
                <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                  Год выпуска
                </div>
                <div className="w-full flex">
                  <div className="w-1/2 pr-1">
                    <div className="w-full border border-gray-300 rounded flex overflow-hidden">
                      <div className="px-2 pt-1 pb-1 text-sm text-gray-600">с</div>
                      <input value={this.state.filter.yearStart} onChange={(e) => this.setYear(e, 0)} onBlur={(e) => this.setBlurYear(e, 0)} type="number" maxLength="4" className="block w-full text-xs outline-none" max="2050" />
                    </div>
                  </div>
                  <div className="w-1/2 pl-1">
                    <div className="w-full border border-gray-300 rounded flex overflow-hidden">
                      <div className="px-2 pt-1 pb-1 text-sm text-gray-600">по</div>
                      <input value={this.state.filter.yearEnd} onChange={(e) => this.setYear(e, 1)} onBlur={(e) => this.setBlurYear(e, 1)} type="number" maxLength="4" className="block w-full text-xs outline-none" max="2050" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full border-t border-gray-300"></div>
              <div className="w-full p-3 pb-4">
                <div className="w-full text-gray-600 text-xs pt-0 pb-2">
                  Дешевле рынка на 20% и больше?
                </div>
                <div className="w-full flex">
                  {this.props.percents.map((item, index) =>
                    <div onClick={() => this.setPercent(item.name)} key={index} className={"px-4 py-1 mr-1 text-xs font-medium border border-gray-300 rounded " + ((this.state.filter.percent === 0 && item.name === 'Нет') || (this.state.filter.percent < 0 && item.name === 'Да') ? 'bg-blue200 text-blue-700' : 'text-gray-700')}>
                      {item.name}
                    </div>
                  )}
                </div>
                <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                  Растоможен
                </div>
                <div className="w-full flex">
                  {this.props.cleared.map((item, index) =>
                    <div onClick={() => this.setCleared(item.name)} key={index} className={"px-4 py-1 mr-1 text-xs font-medium border border-gray-300 rounded " + ((this.state.filter.isCleared && item.name === 'Да') || (this.state.filter.isCleared === false  && item.name === 'Нет') || (this.state.filter.isCleared=== null && item.name === 'Не важно') ? 'bg-blue200 text-blue-700' : 'text-gray-700')}>
                      {item.name}
                    </div>
                  )}
                </div>
                <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                  Состояние
                </div>
                <div className="w-full flex">
                  {this.props.conditions.map((item, index) =>
                    <div onClick={() => this.setStates(item.name)} key={index} className={"px-4 py-1 mr-1 text-xs font-medium border border-gray-300 rounded " + ((this.state.filter.condition === item.name) || (this.state.filter.condition === '' && item.name === 'Не важно') ? 'bg-blue200 text-blue-700' : 'text-gray-700')}>
                      {item.name}
                    </div>
                  )}
                </div>
                <div className="w-full text-gray-600 text-xs pt-3 pb-2">
                  Коробка передач
                </div>
                <div className="w-full flex">
                  {this.props.gearboxes.map((item, index) =>
                    <div onClick={() => this.setBoxes(item.name)} key={index} className={"px-4 py-1 mr-1 text-xs font-medium border border-gray-300 rounded " + ((this.state.filter.gearbox === item.name) || (this.state.filter.gearbox === '' && item.name === 'Не важно') ? 'bg-blue200 text-blue-700' : 'text-gray-700')}>
                      {item.name}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full fixed left-0 bottom-0 p-6">
              <div onClick={() => this.save()} className="w-full py-2 text-center text-white rounded shadow-lg text-base bg-blue200o">
                Сохранить
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Filter;
