import React from "react";

const results = {
  1: {
    country: "RU",
    type: "registration",
    dateOfFirstRegistration: "15.11.2013",
    dateOfLastOperation: "15.11.2013",
    okrug: "Центральный",
    oblast: "Тульская　область",
    area: "300001",
    codeOfTechOperation: "первичная регистрация",
    categoryOfOwner: "частное лицо",
    owner: "ФИЗ　ЛИЦО",
    inn: "-",
    region: "СОВЕТСКИЙ",
    city: "ТУЛА　Г",
    street: "-",
    house: "-",
    appartment: "-",
  },
  2: {
    country: "RU",
    type: "registration",
    dateOfFirstRegistration: "01.01.0001",
    dateOfLastOperation: "26.04.2016",
    okrug: "Центральный",
    oblast: "Москва",
    area: "45 900 000",
    codeOfTechOperation:
      "Изменение собственника по сделкам, произведенным в любой форме (вторичная регистрация)",
    categoryOfOwner: "частное лицо",
    owner: "ФИЗ　ЛИЦО",
    inn: "-",
    region: "-",
    city: "МОСКВА　Г.",
    street: "-",
    house: "-",
    appartment: "-",
  },
};

export const Intro = (props) => {
  return (
    <div
      className="p-4 pt-20 w-full "
      style={{
        position: "absolute",
        top: "0",
        backgroundColor: "#edebeb",
      }}
    >
      <div className="mb-4" style={{ textAlign: "center" }}>
        <div
          className=""
          style={{
            display: "inline-block",
            fontSize: "1.2em",
          }}
        >
          Пример поиска:
        </div>
      </div>
      <div
        className={
          "relative mt-1 bg-white shadow-lg p-1 py-3 mb-4 text-sm flex "
        }
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
        }}
      >
        <div
          className=""
          style={{
            fontSize: "1em",
            paddingLeft: "0.5em",
          }}
        >
          Информация об авто:
        </div>
        <div
          className="px-1 text-xs mt-2"
          style={{
            color: "#848482",
            paddingLeft: "0.8em",
            fontSize: "0.8em",
          }}
        >
          Марка: <span style={{ color: "black" }}>Jeep</span>
        </div>
        <div
          className="px-1 text-xs mt-2"
          style={{
            color: "#848482",
            paddingLeft: "0.8em",
            fontSize: "0.8em",
          }}
        >
          Модель: <span style={{ color: "black" }}>Jeep Grand Cherokke</span>
        </div>
        <div
          className="px-1 text-xs mt-2"
          style={{
            color: "#848482",
            paddingLeft: "0.8em",
            fontSize: "0.8em",
          }}
        >
          Руль: <span style={{ color: "black" }}>левый</span>
        </div>
        <div
          className="px-1 text-xs mt-2"
          style={{
            color: "#848482",
            paddingLeft: "0.8em",
            fontSize: "0.8em",
          }}
        >
          Мощьность: <span style={{ color: "black" }}>261 л.с.</span>
        </div>
        <div
          className="px-1 text-xs mt-2"
          style={{
            color: "#848482",
            paddingLeft: "0.8em",
            fontSize: "0.8em",
          }}
        >
          Год выпуска: 2013 г.
        </div>
      </div>
      {Object.entries(results).map(function (key, index) {
        return (
          <div
            className={
              "relative mt-1 bg-white shadow-lg p-1 py-3 mb-4 text-sm flex "
            }
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "5px",
            }}
          >
            <div
              className=""
              style={{
                fontSize: "1em",
                paddingLeft: "0.5em",
              }}
            >
              Результат <span style={{ color: "black" }}>{key[0]}</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Страна: <span style={{ color: "black" }}>{key[1].country}</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Дата первой регистрации:{" "}
              <span style={{ color: "black" }}>15.11.2013</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Округ: <span style={{ color: "black" }}>центральный</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Область: <span style={{ color: "black" }}>Тульская　область</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Наименование операции:{" "}
              <span style={{ color: "black" }}>первичная регистрация</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Владелец: <span style={{ color: "black" }}>Физ лицо</span>
            </div>
            <div
              className="px-1 text-xs mt-2"
              style={{
                color: "#848482",
                paddingLeft: "0.8em",
                fontSize: "0.8em",
              }}
            >
              Город: <span style={{ color: "black" }}>Тула</span>
            </div>
          </div>
        );
      })}
      <div
        className="py-1 px-2 mt-10 mb-20 text-center text-white rounded shadow-lg bg-blue200o"
        onClick={() => {
          props.start(true);
        }}
      >
        Начать использование
      </div>
    </div>
  );
};
