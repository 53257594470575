import React, { useState, useEffect } from "react";
import { domain, botUrl } from "../../../config/ajax";
import { Redirect } from "react-router-dom";
import axios from "axios";
import imgVisa from "./imgs/visa.png";
import imgMC from "./imgs/mastercard.png";
import imgCP from "./imgs/cloudpayments.png";
import imgCvv from "./imgs/cvv-icon-27.jpg";
import load from "../src/pngegg (1).png";

const publicId = "pk_621ddb0b136db0dec1546c597193c";
const apiSecret = "3b81355c848b6267f0bebd17a8ea19a6";

export const Pay = (props) => {
  const [cvvIcon, setCVVIcon] = useState(false);
  const [cardNum, setCardNum] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [dSecure, setDSecure] = useState();
  const [block, setBlock] = useState(false);
  const cloudPay = (event) => {
    setBlock(true);
    event.preventDefault();
    let checkout = new window.cp.Checkout(
      // public id из личного кабинета
      publicId,
      // тег, содержащий поля данных карты
      document.getElementById("paymentFormSample")
    );
    var result = checkout.createCryptogramPacket();
    if (result.success) {
      // console.log(result.packet);
      let options = {
        CardCryptogramPacket: result.packet,
        ...props.options,
      };
      axios
        .post(`${botUrl}/payment/cloudpayments`, options)
        .then((result) => {
          setBlock(true);
          console.log(result.data);
          if (result.data === "Success") {
            setInterval(alert("Оплата прошла успешно"), 1500);
          } else {
            setDSecure(result.data);
          }
        })
        .catch((error) => {
          setBlock(false);
          console.log(error);
        });
      // сформирована криптограмма
    } else {
      setBlock(false);
      // найдены ошибки в введённых данных, объект `result.messages` формата:
      // { name: "В имени держателя карты слишком много символов", cardNumber: "Неправильный номер карты" }
      // где `name`, `cardNumber` соответствуют значениям атрибутов `<input ... data-cp="cardNumber">`
      for (var msgName in result.messages) {
        alert(result.messages[msgName]);
      }
    }
  };
  const changeHandler = (e) => {
    let value = e.target.value.replace(/ /g, "");
    let card = e.target.value;
    if (value.length % 4 === 0 && value.length < 16) {
      setCardNum((prev) => {
        if (prev.length < card.length) {
          card += " ";
          return card;
        } else {
          return card;
        }
      });
    } else {
      let card = e.target.value;
      setCardNum(card);
    }
    if (value.length === 16) {
      document.downloadForm.month.focus();
    }
  };
  const montChangeHandler = (e) => {
    console.log(e.target.value);
    let value = e.target.value.replace(/0/, "");
    console.log(value);
    if (parseInt(value) <= 12 || value.length === 0) {
      let monthValue;
      switch (value.length) {
        case 0:
          monthValue = "00";
          break;
        case 1:
          monthValue = "0" + value;
          break;
        case 2:
          monthValue = value;
          break;
      }
      setMonth(monthValue);
    }
  };
  const yearChangeHandler = (e) => {
    console.log(e.target.value);
    let value = e.target.value.replace(/0/, "");
    console.log(value);
    if (value.length < 3) {
      let yearValue;
      switch (value.length) {
        case 0:
          yearValue = "00";
          break;
        case 1:
          yearValue = "0" + value;
          break;
        case 2:
          yearValue = value;
          break;
      }
      setYear(yearValue);
    }
    if (value.length > 2) {
      document.downloadForm.cvv.focus();
    }
  };
  const cvvChangeHandler = (e) => {
    console.log(e.target.value);
    let value = e.target.value.replace(/0/, "");
    console.log(value);
    if (value.length <= 3) {
      setCvv(value);
    }
    if (value.length === 3) {
      document.downloadForm.name.focus();
    }
  };
  useEffect(() => {
    if (typeof dSecure === "object") {
      setTimeout(document.downloadForm.submit(), 1000);
    }
  }, [dSecure]);
  return (
    <div
      className={"w-full mt-20"}
      style={{
        display: "flex",
        flexDirection: "column",
        flexFlow: "column",
        position: "fixed",
        zIndex: "1",
        height: "100%",
        backgroundColor: "rgba(211,211,211, 0.5)",
        overflowY: "scroll",
      }}
    >
      <div
        className="p-4 pt-20 w-full "
        style={{
          position: "absolute",
          top: "0",
          zIndex: "1",
          visibility: block ? "visible" : "hidden",
          height: "100vh",
          backgroundColor: "rgba(237, 235, 235, 0.7)",
          backgroundSize: "cover",
        }}
      >
        <img
          className="loading"
          src={load}
          style={{ height: "100px", margin: "auto", marginTop: "40px" }}
        ></img>
      </div>
      <div
        className="w-full mt-10 mb-20 text-m"
        style={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <i
          className="fa fa-times-circle"
          style={{
            position: "absolute",
            right: "1em",
            top: "1em",
          }}
          onClick={() => {
            props.onClose(false);
          }}
        ></i>
        {dSecure ? (
          <form name="downloadForm" action={dSecure.AcsUrl} method="POST">
            <input type="hidden" name="PaReq" value={dSecure.PaReq} />
            <input type="hidden" name="MD" value={dSecure.TransactionId} />
            <input
              type="hidden"
              name="TermUrl"
              value={`${botUrl}/payment/cloudpayments/3dsecure`}
            />
          </form>
        ) : (
          <form
            id="paymentFormSample"
            autoComplete="off"
            className="bg-white mt-6 pb-6"
            name="downloadForm"
            onSubmit={cloudPay}
          >
            <div className="text-center my-6 mx-6 text-gray-700">
              Подписка на ежемесячный доступ к боту CARFASTBOT
            </div>
            <div className="credit-card mx-2">
              <input
                data-cp="cardNumber"
                placeholder="Номер карты"
                className="card-num mx-4 my-2"
                value={cardNum}
                onChange={changeHandler}
                maxLength={19}
                type="text"
              />
              <div className="scnd-row mx-4 my-2">
                {" "}
                <div className="mm-yy">
                  <input
                    data-cp="expDateMonth"
                    placeholder="ММ"
                    className="dd"
                    id="month"
                    type="number"
                    value={month}
                    onChange={montChangeHandler}
                  />
                  <div
                    className="mx-1"
                    style={{ fontSize: "2em", color: "gray" }}
                  >
                    /
                  </div>
                  <input
                    data-cp="expDateYear"
                    placeholder="ГГ"
                    className="dd"
                    id="year"
                    type="number"
                    value={year}
                    onChange={yearChangeHandler}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "right",
                  }}
                >
                  <input
                    data-cp="cvv"
                    placeholder="CVV*"
                    className="cvv"
                    id="cvv"
                    type="number"
                    onChange={cvvChangeHandler}
                  />
                  <span style={{ fontSize: "0.45em", color: "gray" }}>
                    *трехзначное число на обороте карты
                  </span>
                </div>
              </div>

              <input
                type="text"
                data-cp="name"
                placeholder="Имя владельца"
                className="name mx-4 my-2"
                id="name"
              />
              <button
                type="submit"
                className="mx-20 py-2 px-2 my-3 text-center text-s text-white rounded shadow-lg bg-blue200o"
              >
                Оплатить {props.options.Amount.toLocaleString("ru")} тнг.
              </button>
            </div>
          </form>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={imgVisa} style={{ height: "25px" }} className="mx-2"></img>
          <img src={imgMC} style={{ height: "45px" }} className="mx-2"></img>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <img src={imgCP} style={{ height: "70px" }} className="mx-2"></img>
        </div>
      </div>
    </div>
  );
};
