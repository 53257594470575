import React from "react";

export const Results = (props) => {
  console.log(props.data);
  return (
    <div
      className="p-4 pt-20 w-full "
      style={{
        position: "absolute",
        top: "0",
        backgroundColor: "#edebeb",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="mb-4" style={{ textAlign: "center" }}>
        <div
          className=""
          style={{
            display: "inline-block",
            fontSize: "1.2em",
          }}
        >
          Результаты поиска:
        </div>
      </div>
      {props.data.State !== "No results" ? (
        <div
          className={
            "relative mt-1 bg-white shadow-lg p-1 py-3 mb-4 text-sm flex"
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
          }}
        >
          <div
            className=""
            style={{
              fontSize: "1em",
              paddingLeft: "0.5em",
            }}
          >
            Информация об авто:
          </div>
          <div
            className="px-1 text-xs mt-2"
            style={{
              color: "#848482",
              paddingLeft: "0.8em",
              fontSize: "0.8em",
            }}
          >
            Марка: <span style={{ color: "black" }}>{props.data.Brand}</span>
          </div>
          <div
            className="px-1 text-xs mt-2"
            style={{
              color: "#848482",
              paddingLeft: "0.8em",
              fontSize: "0.8em",
            }}
          >
            Модель: <span style={{ color: "black" }}>{props.data.Model}</span>
          </div>
          <div
            className="px-1 text-xs mt-2"
            style={{
              color: "#848482",
              paddingLeft: "0.8em",
              fontSize: "0.8em",
            }}
          >
            Руль:{" "}
            <span style={{ color: "black" }}>
              {props.data.SteeringWheelPlacement}
            </span>
          </div>
          <div
            className="px-1 text-xs mt-2"
            style={{
              color: "#848482",
              paddingLeft: "0.8em",
              fontSize: "0.8em",
            }}
          >
            Мощьность:{" "}
            <span style={{ color: "black" }}>{props.data.Power} л.с.</span>
          </div>
          <div
            className="px-1 text-xs mt-2"
            style={{
              color: "#848482",
              paddingLeft: "0.8em",
              fontSize: "0.8em",
            }}
          >
            Год выпуска:{" "}
            <span style={{ color: "black" }}>{props.data.Year} г.</span>
          </div>
        </div>
      ) : (
        <div
          className={
            "relative mt-1 bg-white shadow-lg p-1 py-3 mb-4 text-sm flex"
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
          }}
        >
          <div
            className=""
            style={{
              display: "inline-block",
              fontSize: "1.2em",
              textAlign: "center",
            }}
          >
            К сожалению, нам не удалось найти историю по вашему авто.
          </div>
        </div>
      )}
      {props.data?.Results
        ? Object.entries(props.data.Results).map(function (key, index) {
            console.log(key);
            return (
              <div
                className={
                  "relative mt-1 bg-white shadow-lg p-1 py-3 mb-4 text-sm flex "
                }
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "5px",
                }}
              >
                <div
                  className=""
                  style={{
                    fontSize: "1em",
                    paddingLeft: "0.5em",
                  }}
                >
                  Результат {key[0]}:
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Страна:{" "}
                  <span style={{ color: "black" }}>{key[1].country}</span>
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Дата операции:{" "}
                  <span style={{ color: "black" }}>
                    {key[1].dateOfLastOperation}
                  </span>
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Округ: <span style={{ color: "black" }}>{key[1].okrug}</span>
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Область:{" "}
                  <span style={{ color: "black" }}>{key[1].oblast}</span>
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Наименование операции:{" "}
                  <span style={{ color: "black" }}>
                    {key[1].codeOfTechOperation}
                  </span>
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Владелец:{" "}
                  <span style={{ color: "black" }}>{key[1].owner}</span>
                </div>
                <div
                  className="px-1 text-xs mt-2"
                  style={{
                    color: "#848482",
                    paddingLeft: "0.8em",
                    fontSize: "0.8em",
                  }}
                >
                  Город: <span style={{ color: "black" }}>{key[1].city}</span>
                </div>
              </div>
            );
          })
        : ""}
      <div
        className="py-1 px-2 mt-10 mb-20 text-center text-white rounded shadow-lg bg-blue200o"
        onClick={() => {
          props.click(false);
        }}
      >
        Список запросов
      </div>
    </div>
  );
};
