import React from "react";

import Logo from "../assets/logo.png";

const Header = (props) => {
  return (
    <div className="w-full bg-white h-auto pt-6 shadow-lg text-left px-6 pb-3 fixed left-0 top-0 z-20">
      {props.logo && (
        <div className="w-full flex">
          <div className="w-12 pr-1">
            <img src={Logo} className="w-full h-auto" alt="logo.png" />
          </div>
          <div className="w-full -mt-1">
            <div className="w-full font-bold text-gray-700 text-base pl-1">
              Car-Fast
            </div>
            <div className="w-full text-gray-500 text-xs pl-1">
              Самые свежие объявления о продаже авто
            </div>
          </div>
        </div>
      )}
      {!props.logo && (
        <div className="w-full flex">
          <div
            className="text-base text-blue-700"
            onClick={() => {
              console.log(props.navigation);
              props.navigation.history.goBack();
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="font-bold text-gray-700 text-base pl-4">
            {props.name}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
