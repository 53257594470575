import React from "react";
import YandexShare from "react-yandex-share";

import Header from "../Header";
import { domain, botUrl } from "../../config/ajax";
import * as qs from "query-string";
import axios from "axios";
// import {  } from "../../constants/event";

import I1 from "../../assets/i1.png";
import I2 from "../../assets/i2.png";
import I3 from "../../assets/i3.png";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadAjax: false,
    };
    this.news = {
      image: "",
      name: "",
      createdAt: "",
      text: "",
    };
    if (this.props.match.params.number) {
      fetch(`${domain}/api/news?_id=${this.props.match.params.number}`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.length > 0) {
              this.news = result[0];
            }
            this.setState({ loadAjax: true });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  parseDate(date, time) {
    console.log(date);
    const d = new Date(date);
    let parse = "";
    if (time) {
      parse = `${d.getHours() < 10 ? "0" + d.getHours() : d.getHours()}:${
        d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()
      }`;
    } else {
      parse = `${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}.${
        d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
      }.${d.getFullYear()}`;
    }
    return parse;
  }

  render() {
    return (
      <div className="w-full pt-15">
        <Header logo={false} name="Новости Бота" navigation={this.props} />
        {this.state.loadAjax && (
          <div className="w-full pt-16">
            <div className="w-full h-auto overflow-hidden flex-shrink-0">
              <img
                src={this.news.image}
                alt={this.news.name}
                className="w-full h-auto min-h-full"
              />
            </div>
            <div className="w-full text-sm text-gray-500 px-6 py-3">
              {this.parseDate(this.news.createdAt, false)}{" "}
              {this.parseDate(this.news.createdAt, true)}
            </div>
            <div className="w-full text-base text-gray-800 px-6 font-bold pb-8">
              {this.news.name}
            </div>
            <div className="w-full text-base text-gray-700 px-6 pb-6 flex">
              <div className="mr-4">Поделиться в:</div>
              <YandexShare
                className="mt-1 ml-4"
                content={{ title: this.news.name }}
                theme={{ lang: "ru", services: "facebook,twitter,whatsapp" }}
              />
            </div>
            <div className="w-full text-base text-gray-800 px-6 border-t border-gray-400 py-6">
              {this.news.text}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default News;
