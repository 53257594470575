import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Modal from "react-modal";
import { Searchpanel } from "./Searchpanel";
import { Intro } from "./Intro";

export const Search = (props) => {
  const [toggle, setToggle] = useState(true);

  return toggle ? (
    <Searchpanel helper={setToggle} />
  ) : (
    <Intro info={props.info} start={setToggle} />
  );
};
