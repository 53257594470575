import React from 'react';

class Brands extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      select: this.props.select,
    };
    this.popular = [];
    this.props.brands.forEach((item, index) => {
      if (item.popular === true && this.popular.length < 5) {
        this.popular.push(item);
      }
    });
  }

  setBrand(name) {
    this.setState({select: name});
  }

  save() {
    // if (this.state.select !== this.props.select) {
      this.props.setbrand(this.state.select);
    // }
  }

  render() {
    return (
      <div className="w-full px-6 pb-6">
        <div className="w-full text-left font-bold text-sm text-gray-700 py-3 pt-0">
          Выберите нужный марку авто
        </div>
        <div onClick={() => this.setState({select: ''})} className="w-full bg-white rounded shadow-lg border border-gray-200 px-3 py-2 text-sm text-gray-700 relative">
          Все марки
          {this.state.select === '' &&
            <span className="absolute right-0 top-0 mr-3 mt-2 text-blue-700">
              <i className="fa fa-check"></i>
            </span>
          }
        </div>
        <div className="w-full bg-white rounded shadow-lg border border-gray-200 px-3 mt-3 pb-2">
          <div className="w-full text-gray-600 text-xs py-3 pb-2">
            Популярные марки авто
          </div>
          {this.popular.map((item, index) =>
            <div onClick={() => this.setBrand(item.name)} key={index} className={"w-full border-gray-300 py-2 text-sm text-gray-800 relative " + (index !== this.popular.length - 1 ? 'border-b' : '')}>
              {item.name}
              {this.state.select === item.name &&
                <span className="absolute right-0 top-0 mr-0 mt-2 text-blue-700">
                  <i className="fa fa-check"></i>
                </span>
              }
            </div>
          )}
        </div>
        <div className="w-full bg-white rounded shadow-lg border border-gray-200 px-3 mt-3 pb-2">
          <div className="w-full text-gray-600 text-xs py-3 pb-2">
            Список марок авто
          </div>
          {this.props.brands.map((item, index) =>
            <div onClick={() => this.setBrand(item.name)} key={index} className={"w-full border-gray-300 py-2 text-sm text-gray-800 relative " + (index !== this.props.brands.length - 1 ? 'border-b' : '')}>
              {item.name}
              {this.state.select === item.name &&
                <span className="absolute right-0 top-0 mr-0 mt-2 text-blue-700">
                  <i className="fa fa-check"></i>
                </span>
              }
            </div>
          )}
        </div>
        <div className="w-full fixed left-0 bottom-0 p-6">
          <div onClick={() => this.save()} className={"w-full py-2 text-center text-white rounded shadow-lg text-base bg-blue200o"}>
            Готово
          </div>
        </div>
      </div>
    );
  }
}

export default Brands;
