import IconSvg from "./icons/Icon"
import React from "react"
import kaspiLogo from './icons/kaspiLogo.svg'
import kaspiQrIcon from './icons/kaspiQrIcon.svg'

const KaspiButton = (props) => {
    const { className, onClick } = props

    const buttonClassNames = [
        className,
        'button',
        'button-kaspi',
    ]

    const handleClick = (e) => {
        onClick && onClick(e)
    }

    return (
        <button
            className={buttonClassNames.join(' ')}
            onClick={handleClick}
        >
            
            <span>Оплатить с </span>
            <IconSvg src={props.isQr ? kaspiQrIcon : kaspiLogo} width='112px' height='28px' color='white' />
        </button>
    )
}

export default KaspiButton