import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { Row, Container, Col, Button } from "reactstrap";
import {
  FaCarSide,
  FaRocket,
  FaPlane,
  FaShuttleVan,
  FaCheck,
} from "react-icons/fa";

export const Item = (props) => {
  const onClickListener = async () => {
    console.log(props.state,14);
    props.click(props.item);
    if (props.item.name !== props.state.myTarif) props.onClick();
  };

  const onCancel = () => {
    props.cancel();
  };

  return (
    <animated.div
      onClick={onClickListener}
      className={
        "relative mt-1 bg-white shadow-lg p-1 py-1 mb-4 text-sm flex " +
        (props.item.name === props.state.myTarif
          ? "border-green"
          : props.item === props.state.rate
          ? "border-choose"
          : "border-gray-200")
      }
    >
      {" "}
      <Container className="p-1">
        <div className="pb-1 flex">
          <Col
            style={{
              display: "inline-block",
              paddingLeft: "0.8em",
              paddingTop: "0.5em",
            }}
          >
            {(() => {
              switch (props.item.name) {
                case "Мини":
                  return (
                    <FaShuttleVan style={{ height: "1.5em", width: "1.5em" }} />
                  );
                  break;
                case "Стандартный":
                  return (
                    <FaCarSide style={{ height: "1.5em", width: "1.5em" }} />
                  );
                  break;
                case "Комфортный":
                  return (
                    <FaPlane style={{ height: "1.5em", width: "1.5em" }} />
                  );
                  break;
                case "VIP":
                  return (
                    <FaRocket style={{ height: "1.5em", width: "1.5em" }} />
                  );
                  break;
              }
            })()}
          </Col>
          <Col
            className=""
            style={{
              display: "inline-block",
              paddingTop: "0.5em",
              paddingLeft: "0.5em",
              fontSize: "1.2em",
            }}
          >
            {props.item.name}
          </Col>
          <Col
            className="flex items-center relative right-0 top-0 font-bold text-gray-700 pl-3 pt-1"
            style={{ fontSize: "0.80em" }}
          >
            {props.item.name === props.state.myTarif ? "Текущий" : ""}
          </Col>
          <div
            className="flex items-center absolute right-0 top-0 font-bold text-gray-700 pr-2 "
            style={{ height: "1.40rem" }}
          >
            <div className="text-s text-gray-700 mt-4 px-1 pt-2">
              {props.state.year ? (
                <div
                  style={{
                    paddingTop: "1em",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      textDecoration: "line-through",
                      color: "red",
                      fontSize: "0.6em",
                    }}
                  >
                    {props.item.month + " ₸ / год"}
                  </div>
                  <div style={{ fontSize: "0.7em", color: "black" }}>
                    {" "}
                    {props.item.year + " ₸ / год"}
                  </div>
                  <div style={{ fontSize: "0.7em" }}>
                    {" "}
                    {"Экономия "}
                    <span style={{ color: "black" }}>
                      {props.item.save + " ₸ "}
                    </span>
                  </div>
                </div>
              ) : (
                <span style={{ color: "black" }}>
                  {props.item.price + " ₸ / мес"}
                </span>
              )}
            </div>
            {props.state.has_subscription &&
            props.item.name === props.state.myTarif ? (
              <Button
                className="text-xs btn-text-white rounded shadow-lg mt-4 px-1 border-red-600"
                style={{ height: "1.50rem" }}
                onClick={onCancel}
              >
                Отменить
              </Button>
            ) : (
              ""
            )}{" "}
          </div>
        </div>
        <Row className="mt-2">
          {props.item.text.map((desc, index) => {
            return (
              <Row
                className="px-1 text-xs"
                style={{ color: "#848482", paddingLeft: "0.8em" }}
              >
                <FaCheck
                  style={{
                    display: "inline-block",
                    marginRight: "1em",
                    color: "gray",
                  }}
                />
                {desc}
              </Row>
            );
          })}
        </Row>
        {}
      </Container>
    </animated.div>
  );
};
