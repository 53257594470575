import React from "react";

import Header from "../Header";

class Help extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: 0,
    };
    this.items = [
      {
        id: 1,
        name: "Как работает @carfastbot?",
        text:
          "Каждую минуту @carfastbot просматривает все сайты с объявлениями о продаже авто и отправляет самые свежие автомобили, если они подходят под ваш фильтр.",
      },
      {
        id: 2,
        name: "Как настроить фильтр?",
        text:
          'Для настройки фильтра перейдите в раздел "Фильтры и нажмите на кнопку "Добавить фильтр".',
      },
      {
        id: 3,
        name: "Могу ли я добавить несколько фильтров?",
        text:
          "Да. В зависимости от вашего тарифа вы можете добавлять несколько фильтров.",
      },
      {
        id: 4,
        name:
          "Я хочу получать новые объявления по Toyota Camry и Audi A6. Как мне сделать это?",
        text:
          "Вам необходимо настроить два фильтра. Один для Toyota Camry, другой для Audi A6.",
      },
      {
        id: 5,
        name:
          "Я не хочу оплачивать своей картой. Боюсь за свои данные. Как мне оплатить?",
        text:
          "На данный момент можно оплатить только платежной картой. Ваши данные не передаются третьим лицам, а команда @carfastbot не имеет к ним доступа. Все платежи и обработка данных происходит на стороне платежного шлюза cloudpayments.kz. Вы можете более детально ознакомиться на сайте cloudpayments.kz",
      },
      {
        id: 6,
        name: "Как отвязать карту, чтобы не списывалась ежемесячная плата?",
        text:
          'Зайдите в личном кабинете в раздел "Тарифы". Рядом с названием вашего текущего тарифа будет кнопка "отменить". При нажатии ваша карта будет отвязана, а тариф закончится через 30 дней после последнего списания денежных средств.',
      },
    ];
    this.items = this.items.map((item) => {
      item.open = false;
      return item;
    });
  }

  componentDidMount() {
    const isTracked = sessionStorage.getItem("HelpPage");
    if (!isTracked) {
      sessionStorage.setItem("HelpPage", true);
    }
  }

  setOpen(id) {
    if (this.state.open === id) {
      this.setState({ open: 0 });
    } else {
      this.setState({ open: id });
    }
  }

  render() {
    return (
      <div className="w-full pt-20">
        <Header logo={false} name="Помощь" navigation={this.props} />
        <div className="w-full px-6 pb-3">
          {this.items.map((item, index) => (
            <div
              key={index}
              className="w-full bg-white shadow-lg border border-gray-200 mb-2 p-4 rounded"
            >
              <div
                className="w-full relative"
                onClick={() => this.setOpen(item.id)}
              >
                <div className="w-full leading-tight text-sm font-medium text-gray-700 pr-8">
                  {item.name}
                </div>
                <div className="absolute right-0 top-0 h-full flex items-center justify-center text-blue-700">
                  {this.state.open === item.id && (
                    <i className="fa fa-minus"></i>
                  )}
                  {this.state.open !== item.id && (
                    <i className="fa fa-plus"></i>
                  )}
                </div>
              </div>
              {this.state.open === item.id && (
                <div className="w-full border-t border-gray-300 text-gray-600 text-sm mt-3 pt-3">
                  {item.text}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Help;
