import React from "react";
import Header from "../Header";
import Filter from "../Filter";
import { domain, botUrl } from "../../config/ajax";
import Logo from "../../assets/logo.png";
import * as qs from "query-string";
import axios from "axios";
import {
  filterPage,
  addFilter,
  editFilter,
  removeFilter,
} from "../../constants/event";

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      filterEditView: false,
      filtersSelectView: false,
      filter: {
        _id: 0,
        isActive: false,
        brand: "",
        model: "",
        yearStart: 1960,
        yearEnd: date.getFullYear(),
        priceStart: 0,
        priceEnd: 0,
        region: "",
        city: "",
        city_name: "",
        isCleared: null,
        condition: "",
        gearbox: "",
        needsPremium: false,
        percent: 0,
      },
      loadAjax: false,
    };
    this.filters = [];
    this.brands = [];
    fetch(`${domain}/api/brands`, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.brands = result;
          this.loadAjax();
        },
        (error) => {
          console.log(error);
        }
      );
    this.regions = [];
    fetch(`${domain}/api/regions`, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.regions = result;
          this.loadAjax();
        },
        (error) => {
          console.log(error);
        }
      );
    this.cleared = [{ name: "Да" }, { name: "Нет" }, { name: "Не важно" }];
    this.conditions = [
      { name: "На ходу" },
      { name: "Аварийная" },
      { name: "Не важно" },
    ];
    this.gearboxes = [
      { name: "Механика" },
      { name: "АКПП" },
      { name: "Не важно" },
    ];
    this.percents = [{ name: "Да" }, { name: "Нет" }];
  }

  loadAjax() {
    if (this.brands.length > 0 && this.regions.length > 0) {
      this.setState({ loadAjax: true });
    }
  }

  async componentDidMount() {
    this.getFilters();
    this.setDefaultFilter();
    const open = sessionStorage.getItem("openFilter");
    if (open === "1") {
      this.newFilter();
    }
    console.log(open);
    sessionStorage.setItem("openFilter", "0");
    this.track();
  }

  sendEvent(event) {
    const body = {
      ...event,
      user_id: sessionStorage.getItem("user_id"),
    };
    axios
      .post(`${botUrl}/api/ga/cabinet`, body)
      .then((result) => {})
      .catch((err) => console.log(err));
  }

  track() {
    const isTracked = sessionStorage.getItem("FilterPage");
    if (!isTracked) {
      const body = {
        ...filterPage,
        user_id: sessionStorage.getItem("user_id"),
      };
      if (!isTracked) {
        axios
          .post(`${botUrl}/api/ga/cabinet`, body)
          .then((result) => {
            sessionStorage.setItem("FilterPage", true);
          })
          .catch((err) => console.log(err));
      }
    }
  }

  setDefaultFilter() {
    const date = new Date();
    const filter = {
      _id: 0,
      isActive: false,
      brand: "",
      model: "",
      yearStart: 1960,
      yearEnd: date.getFullYear(),
      priceStart: 0,
      priceEnd: 0,
      region: "",
      city: "",
      city_name: "",
      isCleared: null,
      condition: "",
      gearbox: "",
      needsPremium: false,
      percent: 0,
    };
    this.setState({ filter: filter });
  }

  newFilter() {
    this.setState({ filterEditView: true });
    this.setDefaultFilter();
  }

  viewEditFilter(filter) {
    this.setState({ filtersSelectView: true, filter: filter });
  }

  cancelEditFilter() {
    this.setState({ filtersSelectView: false });
    this.setDefaultFilter();
  }

  editFilter() {
    this.setState({ filterEditView: true, filtersSelectView: false });
  }

  getFilters() {
    fetch(
      `${domain}/api/filter?telegram_user_id=${sessionStorage.getItem(
        "user_id"
      )}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.filters = result;
          this.setState({ loadAjax: true });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  setFilter(filter) {
    this.setState({ filterEditView: false, loadAjax: false });
    const json = {
      user: sessionStorage.getItem("user_id"),
      token: sessionStorage.getItem("token"),
      _id: this.state.filter._id,
      isActive: false,
      title: this.state.filter.title,
      brand: this.state.filter.brand,
      model: this.state.filter.model,
      yearStart: this.state.filter.yearStart,
      yearEnd: this.state.filter.yearEnd,
      priceStart: Number(
        String(this.state.filter.priceStart).replace(/\s/g, "")
      ),
      priceEnd: Number(String(this.state.filter.priceEnd).replace(/\s/g, "")),
      region: this.state.filter.region,
      city: this.state.filter.city,
      city_name: this.state.filter.city_name,
      isCleared: this.state.filter.isCleared,
      condition: this.state.filter.condition,
      gearbox: this.state.filter.gearbox,
      needsPremium: false,
      percent: this.state.filter.percent,
    };
    fetch(`${domain}/api/filter/save`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(json),
      header: {
        "Content-Type": "application/text-plain",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.getFilters();
          if (this.state.filter._id) this.sendEvent(editFilter);
          else this.sendEvent(addFilter);
        },
        (error) => {
          this.getFilters();
        }
      );
  }

  removeFilter() {
    this.setState({ loadAjax: false });
    const json = {
      id: this.state.filter._id,
      user: sessionStorage.getItem("user_id"),
      token: sessionStorage.getItem("token"),
    };
    fetch(`${domain}/api/delete`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(json),
      header: {
        "Content-Type": "application/text-plain",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.getFilters();
          this.sendEvent(removeFilter);
        },
        (error) => {
          this.getFilters();
        }
      );
    this.setState({ filtersSelectView: false });
  }

  priceParse(price) {
    let result = price;
    if (result !== 0) {
      result = String(result).replace(/\s/g, "");
      if (result.length === 4) {
        result = `${result.slice(0, 1)} ${result.slice(
          1,
          result.replace(/\s/g, "").length
        )}`;
      } else if (result.length === 5) {
        result = `${result.slice(0, 2)} ${result.slice(
          2,
          result.replace(/\s/g, "").length
        )}`;
      } else if (result.length === 6) {
        result = `${result.slice(0, 3)} ${result.slice(
          3,
          result.replace(/\s/g, "").length
        )}`;
      } else if (result.length === 7) {
        result = `${result.slice(0, 1)} ${result.slice(1, 4)} ${result.slice(
          4,
          result.replace(/\s/g, "").length
        )}`;
      } else if (result.length === 8) {
        result = `${result.slice(0, 2)} ${result.slice(2, 5)} ${result.slice(
          5,
          result.replace(/\s/g, "").length
        )}`;
      } else if (result.length === 9) {
        result = `${result.slice(0, 3)} ${result.slice(3, 6)} ${result.slice(
          6,
          result.replace(/\s/g, "").length
        )}`;
      } else {
      }
    }
    return result;
  }

  render() {
    return (
      <div className="w-full pt-20 pb-16">
        <div className="w-full bg-white h-auto pt-6 shadow-lg text-left px-6 pb-3 fixed left-0 top-0 z-20">
          <div className="w-full flex">
            <div
              className="text-base text-blue-700"
              onClick={() =>
                this.state.filterEditView
                  ? this.setState({ filterEditView: false })
                  : this.props.history.push("/")
              }
            >
              <i className="fa fa-arrow-left"></i>
            </div>
            <div className="font-bold text-gray-700 text-base pl-4">
              Фильтры
            </div>
          </div>
        </div>
        {this.state.loadAjax && (
          <div className="w-full">
            {this.state.filterEditView === false && (
              <div className="w-full px-6">
                {this.filters.length < 1 && (
                  <h5>
                    У вас нет фильтров. Нажмите на кнопку ниже и добавьте свой
                    первый фильтр.
                  </h5>
                )}
                {this.filters.map((item, index) => (
                  <div
                    key={index}
                    className="w-full bg-white rounded shadow-lg border border-gray-200 mb-3 relative"
                  >
                    <div className="w-full p-3">
                      <span
                        onClick={() => this.viewEditFilter(item)}
                        className="absolute right-0 top-0 mt-2 mr-4 text-2xl text-blue-700"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </span>
                      {item.isActive === true && (
                        <div className="w-20 bg-blue-300 py-1 text-xs text-center text-white rounded">
                          активен
                        </div>
                      )}
                      {item.isActive === false && (
                        <div className="w-20 bg-red-500 py-1 text-xs text-center text-white rounded">
                          неактивен
                        </div>
                      )}
                      <div className="w-full text-base font-medium pt-2 text-gray-700">
                        {item.brand && item.brand !== "all"
                          ? item.brand
                          : "Любая марка авто"}{" "}
                        {item.model !== "Все модели" ? item.model : ""}
                      </div>
                      <div className="w-full text-xs font-medium pb-2 text-gray-500">
                        {item.yearStart} {item.yearStart !== "" ? "-" : ""}{" "}
                        {item.yearEnd}
                      </div>
                      <div className="w-full text-sm font-medium pb-2 text-gray-700 underline">
                        {this.priceParse(item.priceStart)} тг -{" "}
                        {this.priceParse(item.priceEnd)} тг
                      </div>
                      <div className="w-full flex pt-3">
                        <div className="text-xs text-gray-600">Регион:</div>
                        <div className="w-full text-right text-xs text-gray-700 font-medium">
                          {item.region === "" ? "Все регионы" : item.region}
                        </div>
                      </div>
                      <div className="w-full flex pt-1">
                        <div className="text-xs text-gray-600 flex-shrink-0">
                          Цена ниже рынка на 20% и больше:
                        </div>
                        <div className="w-full text-right text-xs text-gray-700 font-medium">
                          {item.percent < 0 ? "Да" : "Нет"}
                        </div>
                      </div>
                      <div className="w-full flex pt-1">
                        <div className="text-xs text-gray-600 flex-shrink-0">
                          Растаможен в РК:
                        </div>
                        <div className="w-full text-right text-xs text-gray-700 font-medium">
                          {item.isCleared
                            ? "Да"
                            : item.isCleared === null
                            ? "Не важно"
                            : "Нет"}
                        </div>
                      </div>
                      <div className="w-full flex pt-1">
                        <div className="text-xs text-gray-600">Состояние:</div>
                        <div className="w-full text-right text-xs text-gray-700 font-medium">
                          {item.condition === "" ? "Не важно" : item.condition}
                        </div>
                      </div>
                      <div className="w-full flex pt-1">
                        <div className="text-xs text-gray-600 flex-shrink-0">
                          Коробка передач:
                        </div>
                        <div className="w-full text-right text-xs text-gray-700 font-medium">
                          {item.gearbox === "" ? "Не важно" : item.gearbox}
                        </div>
                      </div>
                    </div>
                    {item.needsPremium && !item.isActive && (
                      <div className="w-full">
                        <div className="w-full border-t border-gray-200"></div>
                        <div className="w-full p-3 mt-3">
                          <div className="w-full p-3 bg-yellow-200">
                            <div className="w-full flex">
                              <div className="text-sm pr-3 text-red-600">
                                <i className="fa fa-warning"></i>
                              </div>
                              <div className="text-sm pr-3 text-gray-800 font-bold">
                                Внимание!
                              </div>
                            </div>
                            <div className="text-xs pr-3 text-gray-600 py-2">
                              Для работы данного фильтра необходимо подключить
                              тариф «Комфортный»
                            </div>
                            <span
                              className="underline text-blue-700 font-medium text-sm"
                              onClick={() => {
                                this.props.history.push("rate");
                              }}
                            >
                              Подключить
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {this.state.filtersSelectView === true && (
                  <div
                    className="fixed left-0 top-0 w-full h-full z-50"
                    style={{ background: "rgba(0, 0, 0, 0.5)" }}
                  >
                    <div className="w-full fixed left-0 bottom-0 p-6">
                      <div className="w-full bg-white shadow-lg rounded border border-gray-200 p-3">
                        <div className="w-full flex mb-5">
                          <div className="pr-3 text-blue-600 text-base">
                            <i className="fa fa-trash"></i>
                          </div>
                          <div
                            className="text-gray-600 text-sm"
                            style={{ marginTop: "0.2rem" }}
                            onClick={() => this.removeFilter()}
                          >
                            Удалить
                          </div>
                        </div>
                        <div className="w-full flex mb-6 border-t border-gray-300 pt-3">
                          <div className="pr-3 text-blue-600 text-base">
                            <i className="fa fa-pencil"></i>
                          </div>
                          <div
                            className="text-gray-600 text-sm"
                            style={{ marginTop: "0.2rem" }}
                            onClick={() => this.editFilter()}
                          >
                            Редактировать
                          </div>
                        </div>
                        <div
                          onClick={() => this.cancelEditFilter()}
                          className="w-full py-2 text-center text-sm text-blue-600 border border-blue-600 rounded font-medium"
                        >
                          Отмена
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.filtersSelectView === false && (
                  <div className="w-full fixed left-0 bottom-0 p-6 z-50">
                    <div
                      onClick={() => this.newFilter()}
                      className="w-full py-2 text-center bg-white border border-green-500 text-green-500 rounded shadow-lg text-base"
                    >
                      Добавить фильтр
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.state.filterEditView === true && (
              <Filter
                filter={this.state.filter}
                brands={this.brands}
                regions={this.regions}
                cleared={this.cleared}
                conditions={this.conditions}
                gearboxes={this.gearboxes}
                percents={this.percents}
                setfilter={this.setFilter.bind(this)}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Filters;
