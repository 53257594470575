import React from "react";
import Header from "../Header";
import { domain, botUrl } from "../../config/ajax";
import { Search } from "./Search/Search";
import { Results } from "./Results/Results";
import axios from "axios";
import qs from "qs";
import load from "./src/pngegg (1).png";

class Stocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stocksLoad: false,
      vin: {},
      number: 0,
      response: "",
      info: false,
      toggle: true,
    };
    this.toTop = this.toTop.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.items = [];
    fetch(`${domain}/api/checkvin`, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result, 21);
          this.items = result;
          this.setState({ stocksLoad: true });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.vin !== prevState.vin) {
      this.setState({ info: !this.state.info });
    }
    if (this.state.info !== true) {
      window.scrollTo(0, 0);
    }
  }

  async clickHandler() {
    this.setState({ toggle: !this.state.toggle });
    let res = await axios(botUrl + "/car_num/get_vin", {
      method: "POST",
      data: { car_num: "140HTA14" },
    }).catch((error) => {
      setTimeout(
        alert(
          "Не правильно введен номер авто, vin код не найден. Попробуйте еще раз."
        ),
        1500
      );
    });
    //this.setState({ toggle: !this.state.toggle });
  }

  toTop() {
    this.setState({ toggle: true, info: false });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div
        className="w-full pt-20"
        style={{
          position: "relative",
          height: "100%",
          backgroundColor: "#edebeb",
          backgroundSize: "cover",
        }}
      >
        <Header logo={false} name="Проверка авто" navigation={this.props} />
        {!this.state.info ? <Search search={this.clickHandler} /> : ""}
        <div
          className="p-4 pt-20 w-full "
          style={{
            position: "absolute",
            top: "0",
            visibility:
              !this.state.toggle && !this.state.info ? "visible" : "hidden",
            zIndex: "1",
            height: "100%",
            backgroundColor: "rgba(237, 235, 235, 0.7)",
            backgroundSize: "cover",
          }}
        >
          <img
            className="loading"
            src={load}
            style={{ height: "100px", margin: "auto", marginTop: "40px" }}
          ></img>
        </div>
      </div>
    );
  }
}

export default Stocks;
