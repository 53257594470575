import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import load from "../../src/pngegg (1).png";
import pay from "../../../../assets/iphone-num.png";
import { domain, botUrl } from "../../../../config/ajax";

export const Searchpanel = (props) => {
  const [number, setNumber] = useState("");
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [correct, setCorrect] = useState("");
  const [id, setId] = useState("");
  const search = () => {
    if (
      number.match(/^[0-9]{3}[A-Z]{2,3}[0-9]{2}/) ||
      number.match(/^[A-Z]{1}[0-9]{3}[A-Z]{2,3}/)
    ) {
      setLoading(true);
      axios(botUrl + "/car_num/get_vin", {
        method: "POST",
        data: { id: sessionStorage.getItem("user_id"), car_num: number },
      })
        .then((result) => {
          console.log(result.data.dataId);
          setId(result.data.dataId);
          setStatus(result.data.status);
        })
        .catch((error) => {
          setLoading(false);
          setTimeout(
            alert(
              "Не правильно введен номер авто, vin код не найден. Попробуйте еще раз."
            ),
            1500
          );
        });
    } else {
      setCorrect(
        <div
          style={{
            fontSize: "0.9em",
            color: "gray",
            position: "absolute",
            textAlign: "center",
            left: "0",
            bottom: 0,
          }}
        >
          Не верно введены данные, данные принимаются в форматах 111ХХХ01,
          111ХХ01, А111ААА, А111АА.
        </div>
      );
      setTimeout(() => {
        setCorrect("");
      }, 3000);
      setNumber("");
    }
  };
  useEffect(() => {
    if (status === "OK") {
      console.log(status);
      setLoading(false);
      setModal(true);
    }
  }, [status]);
  return (
    <div
      style={{
        position: loading ? "fixed" : "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#edebeb",
        backgroundSize: "cover",
      }}
    >
      <Modal
        isOpen={modal}
        className="w-full"
        onRequestClose={() => {
          setStatus("");
          setModal(false);
          setNumber("");
        }}
        shouldCloseOnOverlayClick={true}
        overlayClassName="Overlay  Overlay--before-close"
        closeTimeoutMS={200}
      >
        <div
          className="w-full bg-white px-6 pt-0 shadow-lg mt-20 text-left px-6 pb-3 z-20"
          style={{
            position: "absolute",
            height: "80vh",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              fontSize: "3vh",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <div>Ожидается оплата.</div>
            <div>
              <span
                style={{
                  fontSize: "2vh",
                }}
              >
                id:
              </span>
              <span>{id}</span>
            </div>
          </div>
          <div
            style={{
              fontSize: "2vh",
              margin: "2vh",
              textAlign: "center",
            }}
          >
            Ваш запрос поступил в обработку, оплатите Kaspi переводом так же,
            как показанно на примере, а затем ожидайте уведомление от нашего
            бота.
          </div>
          <div
            className="mt-0"
            style={{
              position: "relative",
              height: "50vh",
              margin: "auto",
            }}
          >
            <div
              style={{
                width: "60px",
                height: "20px",
                overflow: "auto",
                position: "absolute",
                margin: "auto",
                top: "auto",
                left: 0,
                bottom: "23%",
                right: 0,
              }}
            >
              2 500 ₸
            </div>
            <div
              style={{
                width: "60px",
                height: "20px",
                overflow: "auto",
                position: "absolute",
                margin: "auto",
                top: "auto",
                left: "25%",
                bottom: "2%",
                right: "auto",
                fontSize: "0.8em",
              }}
            >
              {id}
            </div>
            <img src={pay} style={{ height: "100%", margin: "auto" }}></img>
          </div>
          <Link to="../history">
            <span
              className="py-1 px-2 mb-6 mt-6 mb-6 mx-10 text-center text-white rounded shadow-lg bg-blue200o"
              style={{
                textAlign: "center",
                display: "block",
              }}
            >
              Ок
            </span>
          </Link>
        </div>
      </Modal>
      <div
        className="p-4 pt-20 w-full "
        style={{
          position: "absolute",
          top: "0",
          zIndex: "1",
          visibility: loading ? "visible" : "hidden",
          height: "100%",
          backgroundColor: "rgba(237, 235, 235, 0.7)",
          backgroundSize: "cover",
        }}
      >
        <img
          className="loading"
          src={load}
          style={{ height: "100px", margin: "auto", marginTop: "40px" }}
        ></img>
      </div>
      <div
        className={
          "relative bg-white shadow-lg px-5 pt-4 mx-3 py-1 mb-2 text-sm flex "
        }
        style={{
          // textAlign: "justify",
          flexDirection: "column",
          borderRadius: "10px",
          alignItems: "center",
          justifyContent: "center",
          color: "gray",
          fontSize: "1em",
          textAlign: "center",
        }}
      >
        Данная функция позволяет узнать количество владельцев автомобиля до вас.
        Вы узнаете сколько раз, в каком регионе и когда ставился на учет
        автомобиль.
        <br /> Для ознакомления можете открыть пример ниже.
        <div
          className="py-1 px-2 mb-2 mt-1 text-center text-white rounded shadow-lg bg-blue200o"
          style={{
            textAlign: "center",
          }}
          onClick={() => {
            props.helper(false);
          }}
        >
          Пример
        </div>
      </div>
      <Link to="../history">
        <span
          className="py-1 px-2 mb-6 mt-6 mb-6 mx-10 text-center text-white rounded shadow-lg bg-blue200o"
          style={{
            textAlign: "center",
            display: "block",
          }}
        >
          История ваших запросов
        </span>
      </Link>
      <div
        className={
          "relative mt-4 mb-20 bg-white shadow-lg pt-2 p-1 mx-3 py-1 mb-2 text-sm flex "
        }
        style={{
          textAlign: "center",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "inline-block",
            paddingTop: "0.5em",
            paddingLeft: "0.5em",
            fontSize: "1.2em",
          }}
        >
          Введите номер авто:
        </div>
        <div className="w-full p-6 pb-12" style={{ position: "relative" }}>
          <input
            className="my-1 py-2 input-num"
            style={{
              position: "relative",
              width: "70%",
              textAlign: "center",
              fontSize: "0.9em",
            }}
            autoCapitalize="characters"
            value={number}
            placeholder="Введите гос. номер автомобиля"
            onChange={(item) => {
              setNumber(item.target.value);
            }}
          ></input>
          {correct}
        </div>
        <div
          className="py-1 px-2 mb-6 mx-10 mt-2 text-center text-white rounded shadow-lg bg-blue200o"
          style={{
            textAlign: "center",
          }}
          onClick={search}
        >
          Найти
        </div>
      </div>
    </div>
  );
};
