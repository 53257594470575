import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import * as qs from "query-string";
import Home from "./components/screens/Home";
import Filters from "./components/screens/Filters";
import Stocks from "./components/screens/Stocks";
import Help from "./components/screens/Help";
import Rate from "./components/screens/Rate";
import News from "./components/screens/News";
import PlaceHolder from "./components/screens/PlaceHolder";
import History from "./components/screens/History";

let user = {};
// const get = window.location.search.replace('?','').split('&').reduce(
// function(p, e) {
//     const a = e.split('=');
//       p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
//     return p;
// },{});

const get = qs.parse(window.location.search);
if (get.user_id && get.token) {
  console.log(get);
  sessionStorage.setItem("user_id", get.user_id);
  sessionStorage.setItem("token", get.token);
  user.id = get.user_id;
  user.token = get.token;
} else {
  if (sessionStorage.getItem("user_id")) {
    user.id = sessionStorage.getItem("user_id");
  }
  if (sessionStorage.getItem("token")) {
    user.token = sessionStorage.getItem("token");
  }
}
console.log(user.id);
if (get.page === "history") {
  window.location.replace("/history");
}
if ((!user.id && !user.token) || !user.id || !user.token) {
  if (window.location.pathname !== "/") {
    window.location.replace("/");
  }
}

/*
window.addEventListener("unload", function() {
  sessionStorage.removeItem('user_id');
  sessionStorage.removeItem('token');
});
*/

function App() {
  return (
    <div className="w-full h-full bg-gray-100 absolute left-0 top-0 overflow-y-auto">
      {user.id && user.token && (
        <Switch>
          <Route exact path="/" component={Home} navigation={BrowserRouter} />
          <Route
            exact
            path="/filter"
            component={Filters}
            navigation={BrowserRouter}
          />
          <Route
            exact
            path="/stocks"
            component={Stocks}
            navigation={BrowserRouter}
          />
          <Route
            exact
            path="/help"
            component={Help}
            navigation={BrowserRouter}
          />
          <Route
            exact
            path="/rate"
            component={Rate}
            navigation={BrowserRouter}
          />
          <Route
            exact
            path="/searches"
            component={News}
            navigation={BrowserRouter}
          />
          <Route
            exact
            path="/news/:number"
            component={News}
            navigation={BrowserRouter}
          />
          <Route
            exact
            path="/history"
            component={History}
            navigation={BrowserRouter}
          />
        </Switch>
      )}
      {!user.id && !user.token && (
        <Switch>
          <Route
            exact
            path="/"
            component={PlaceHolder}
            navigation={BrowserRouter}
          />
        </Switch>
      )}
    </div>
  );
}

export default App;
