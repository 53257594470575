import React, { useState, useEffect } from "react";
import Header from "../Header";
import axios from "axios";
import load from "./src/pngegg (1).png";
import { Results } from "./Results/Results";
import { Link } from "react-router-dom";
import { botUrl } from "../../config/ajax";

const History = (props) => {
  const [block, setBlock] = useState(true);
  const [choose, setChoose] = useState(false);
  const [results, setResults] = useState(() => {
    axios(botUrl + "/car_num/get_history/", {
      method: "POST",
      data: { id: sessionStorage.getItem("user_id") },
    }).then((result) => {
      console.log(sessionStorage.getItem("user_id"));
      setResults(result.data);
    });
  });

  useEffect(() => {
    if (results) {
      setBlock(false);
      console.log(results);
    }
  }, [results]);

  return (
    <div
      className="w-full pt-20"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#edebeb",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Header logo={false} name="История поиска" navigation={props} />
      <div
        className="p-4 pt-20 w-full "
        style={{
          position: "absolute",
          top: "0",
          zIndex: "1",
          visibility: block ? "visible" : "hidden",
          height: "100%",
          backgroundColor: "rgba(237, 235, 235, 0.7)",
          backgroundSize: "cover",
        }}
      >
        <img
          className="loading"
          src={load}
          style={{ height: "100px", margin: "auto", marginTop: "40px" }}
        ></img>
      </div>
      <div>
        {choose ? (
          <Results data={choose.result} click={setChoose} />
        ) : results ? (
          results.map((value, index) => {
            return (
              <div
                className={
                  "relative mt-4 bg-white shadow-lg pt-2 p-5 mx-3 py-1 mb-2 text-sm flex "
                }
                onClick={() => {
                  if (value.paymentStatus) {
                    setChoose(value);
                  }
                }}
                style={{
                  textAlign: "left",
                  flexDirection: "column",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    flexDirection: "column",
                    fontSize: "1.2em",
                  }}
                >
                  Запрос №{value.id}:
                </div>
                <div
                  style={{
                    textAlign: "left",
                    flexDirection: "column",
                    fontSize: "1em",
                  }}
                >
                  Гос. номер: {value.car_num}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    flexDirection: "column",
                    fontSize: "1em",
                  }}
                >
                  Статус:{" "}
                  {value.paymentStatus ? (
                    <span style={{ color: "green" }}>Результат готов</span>
                  ) : (
                    <span style={{ color: "red" }}>Ожидается оплата</span>
                  )}
                </div>
              </div>
            );
          })
        ) : null}
      </div>
      <Link to="./stocks">
        <span
          className="py-1 px-2 mb-6 mt-10 mb-20 mx-10 text-center text-white rounded shadow-lg bg-blue200o"
          style={{
            textAlign: "center",
            display: "block",
          }}
        >
          Новый поиск
        </span>
      </Link>
    </div>
  );
};

export default History;
