import React from 'react';

class Regions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      select: this.props.select,
      view: '',
      city: '',
      city_name: '',
    };
    this.popular = [];
    this.props.regions.forEach((item) => {
      item.cities.forEach((item2) => {
        if (item2.popular === true && this.popular.length < 4) {
          this.popular.push(item2);
        }
      });
    });
  }

  setRegion(name, region = false, city = '') {
    if (region) {
      if (this.state.view === name) {
        this.setState({view: ''});
      } else {
        this.setState({view: name});
      }
      this.setState({city: '', city_name: ''});
    } else {
      this.setState({city: city, city_name: name});
    }
    this.setState({select: name});
  }

  save() {
    // if (this.state.select !== this.props.select) {
      this.props.setregion(this.state.select);
      this.props.setcity({city: this.state.city, city_name: this.state.city_name});
    // }
  }

  render() {
    return (
      <div className="w-full px-6 pb-6">
        <div className="w-full text-left font-bold text-sm text-gray-700 py-3 pt-0">
          Выберите нужный регион
        </div>

        <div onClick={() => this.setState({select: ''})} className="w-full bg-white rounded shadow-lg border border-gray-200 px-3 py-2 text-sm text-gray-700 relative">
          Все регионы
          {(this.state.select === 'Все регионы'  || this.state.select === '') &&
            <span className="absolute right-0 top-0 mr-3 mt-2 text-blue-700">
              <i className="fa fa-check"></i>
            </span>
          }
        </div>
        <div className="w-full bg-white rounded shadow-lg border border-gray-200 px-3 mt-3 pb-2">
          <div className="w-full text-gray-600 text-xs py-3 pb-2">
            Популярные города
          </div>
          {this.popular.map((item, index) =>
            <div onClick={() => this.setRegion(item.name, false, item.city)} key={index} className={"w-full border-gray-300 py-2 text-sm text-gray-800 relative " + (index !== this.popular.length - 1 ? 'border-b' : '')}>
              {item.name}
              {this.state.select === item.name &&
                <span className="absolute right-0 top-0 mr-0 mt-2 text-blue-700">
                  <i className="fa fa-check"></i>
                </span>
              }
            </div>
          )}
        </div>
        <div className="w-full bg-white rounded shadow-lg border border-gray-200 px-3 mt-3 pb-2">
          <div className="w-full text-gray-600 text-xs py-3 pb-2">
            Список областей
          </div>
          {this.props.regions.map((item, index) =>
            <div key={index} className="w-full">
              <div onClick={() => this.setRegion(item.name, true)} className={"w-full border-gray-300 py-2 text-sm text-gray-800 relative " + (index !== this.props.regions.length - 1 ? 'border-b' : '')}>
                {item.name}
                {this.state.select === item.name &&
                  <span className="absolute right-0 top-0 mr-0 mt-2 text-blue-700">
                    <i className="fa fa-check"></i>
                  </span>
                }
                {this.state.select !== item.name && this.state.view !== item.name &&
                  <span className="absolute right-0 top-0 mr-0 mt-2 text-gray-400">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                }
                {this.state.select !== item.name && this.state.view === item.name &&
                  <span className="absolute right-0 top-0 mr-0 mt-2 text-gray-400">
                    <i className="fa fa-chevron-down"></i>
                  </span>
                }
              </div>
              {this.state.view === item.name &&
                <div className="w-full">
                  {item.cities.map((item2, index2) =>
                    <div key={index2} onClick={() => this.setRegion(item2.name, false, item2.city)} className="w-full py-2 text-sm text-gray-700 pl-4 relative">
                      {item2.name}
                      {this.state.select === item2.name &&
                        <span className="absolute right-0 top-0 mr-0 mt-2 text-blue-700">
                          <i className="fa fa-check"></i>
                        </span>
                      }
                    </div>
                  )}
                </div>
              }
            </div>
          )}
        </div>
        <div className="w-full fixed left-0 bottom-0 p-6">
          <div onClick={() => this.save()} className={"w-full py-2 text-center text-white rounded shadow-lg text-base bg-blue200o"}>
            Готово
          </div>
        </div>
      </div>
    );
  }
}

export default Regions;
