module.exports = {
  mainPage: {
    action: "Главная",
    label: "Посетить главную страницу",
    category: "КАБИНЕТ",
  },
  filterPage: {
    action: "Фильтры",
    label: "Посетить страницу фильтров",
    category: "КАБИНЕТ",
  },
  tariffsPage: {
    action: "Тарифы",
    label: "Посетить страницу тарифов",
    category: "КАБИНЕТ",
  },
  helpPage: {
    action: "Помощь",
    label: "Посетить страницу помощи",
    category: "КАБИНЕТ",
  },
  removeFilter: {
    action: "Удалить",
    label: "Удалить фильтр",
    category: "ФИЛЬТР",
  },
  addFilter: {
    action: "Добавить",
    label: "Добавить фильтр",
    category: "ФИЛЬТР",
  },
  editFilter: {
    action: "Отредактировать",
    label: "Отредактировать фильтр",
    category: "ФИЛЬТР",
  },
  doPaymentMini: {
    action: "Оплатить - Мини",
    label: "Нажал кнопку подключить тариф Мини",
    category: "ОПЛАТА",
  },
  doPaymentStandard: {
    action: "Оплатить - Стандартный",
    label: "Нажал кнопку подключить тариф Стандартный",
    category: "ОПЛАТА",
  },
  doPaymentComfort: {
    action: "Оплатить - Комфортный",
    label: "Нажал кнопку подключить тариф Комфортный",
    category: "ОПЛАТА",
  },
  doPaymentVip: {
    action: "Оплатить - VIP",
    label: "Нажал кнопку подключить тариф VIP",
    category: "ОПЛАТА",
  },
  cancelPayment: {
    action: "Отменить",
    label: "Отмена оплаты",
    category: "ОПЛАТА",
  },
  errorPayment: {
    action: "Ошибка",
    label: "",
    category: "ОПЛАТА",
  },
};
