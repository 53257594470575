import { useEffect, useState } from "react"
import { io } from 'socket.io-client';
import { socketUrl } from "../../../config/ajax";
import useCheckMobileScreen from '../shared/mobile-check'
import IconSvg from './icons/Icon'
import React from 'react';
import QRCode from "react-qr-code";
import KaspiButton from "./kaspiButton";

import errorKaspi from './icons/errorKaspi.svg'
import left from './icons/left.svg'
import kaspiGold from './icons/kaspiGold.svg'
import kaspiLogoForQr from './icons/kaspiLogoForQr.svg'
import kaspiPaymentMethodsRus from './icons/kaspiPaymentMethodsRus.svg'
import kaspiQrScanAndPayRus from './icons/kaspiQrScanAndPayRus.svg'
import successKaspi from './icons/successKaspi.svg'

const currencyFormat = (num, w = true) => {
    try {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
        })

        const result = formatter.format(num).replace(/,/g, ' ')

        return result + (w ? ' ₸' : '')
    } catch (e) {
        return `0 ${w ? ' ₸' : ''}`
    }
}

const KaspiQRPayment = ({userId, amount, term, rate, onBack}) => {
    const [socket, setSocket] = useState(null)
    const [qrToken, setQrToken] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')
    const [paymentLink, setPaymentLink] = useState('')
    const [timer, setTimer] = useState(null);
    const [isTimeout, setIsTimeout] = useState(false);

    const [isButtonClicked, setIsButtonClicked] = useState(false)

    const isMobile = useCheckMobileScreen()

    useEffect(() => {
        console.log(isButtonClicked)
        // setIsButtonClicked(isButtonClicked)
    }, [isButtonClicked])

    const resetTimer = () => {
        if (timer > 0){
            clearTimeout(timer)
            setTimer(null)
        }
    }

    const startTimer = (duration) => {
        resetTimer();
        const newTimer = setTimeout(() => {
            setIsTimeout(true);
        }, duration * 1000);
        setTimer(newTimer);
    };

    useEffect(() => {
        console.log(`Creating connection to:\n${socketUrl}`)

        const webSocket = new WebSocket(socketUrl);

        webSocket.onopen = () => {
            console.log('WebSocket connection established');
            setSocket(webSocket);
        }

        webSocket.onclose = () => {
            console.log('WebSocket connection closed');
            setSocket(null);
        }

        webSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
        }

        webSocket.onmessage = (message) => {
            console.log("Recieved message from server...")
            console.log(message)
            try {
                const data = JSON.parse(message.data)

                console.log(`Message from server: `,data)

                switch (data.event) {
                    case 'getCarFastPaymentQrToken':
                        console.log("Recieved token message...")
                        handleGetCarFastPaymentQrToken(data.data);
                        break;
                    case 'getCarFastPaymentQrLink':
                        console.log("Recieved link message...")
                        handleGetCarFastPaymentQrLink(data.data);
                        break;
                    case 'onPaymentStatusChange':
                        console.log("Recieved status change message...")
                        onPaymentStatusChange(data.data);
                        break;
                    default:
                        console.error('Unknown event:', data.event);
                }
            } catch (e) {
                console.error('Error parsing message:', e);
            };

            return () => {
                webSocket.close()
            }
        }
    }, [])

    const onKaspiPayment = () => {
        if (!socket) {console.log('no socket'); return};

        const event = isMobile? 'getCarFastPaymentQrLink' : 'getCarFastPaymentQrToken'
        const payload = {
            userId,
            amount,
            tariff: rate,
            term
        }

        console.log(payload)

        console.log("Отправка запроса оплаты...")

        socket.send(JSON.stringify({event, payload}))
    }

    const handleGetCarFastPaymentQrToken = (data) => {
        console.log(`Setting this params:\nQrToken:${data.qrToken}\nStatus:${data.status}\n` )
        setQrToken(data.qrToken)
        setPaymentStatus(data.status)
        startTimer(data.qrCodeScanWaitTimeout)
    }

    const handleGetCarFastPaymentQrLink = (data) => {
        console.log(`Setting this params:\nQrLink:${data.qrLink}\nStatus:${data.status}\n` )
        setPaymentLink(data.qrLink)
        setPaymentStatus(data.status)
        startTimer(data.linkActivationWaitTimeout);
    }

    const onPaymentStatusChange = (data) => {
        console.log('Payment status changed...')
        if (data.status === 'Wait') {
            setPaymentStatus(data.status)
            resetTimer()
            startTimer(data.paymentConfirmationTimeout);
        } else if (data.status === 'Processed') {
            setPaymentStatus(data.status)
            resetTimer()
            setIsTimeout(false)
        } else if (data.status === "Error") {
            setPaymentStatus(data.status)
            resetTimer()
        }
    }

    const onButtonClick = () => {
        setIsButtonClicked(true);
        console.log("link:")
        console.log(paymentLink)
        if (paymentLink) {
            console.log("Mobile payment...")
            window.open(paymentLink, '_blank');
        };
    }

    useEffect(() => {
        return () => {
            resetTimer()
        }
    }, []);

    useEffect(() => {
        if (isMobile && paymentLink) {
            console.log("Mobile payment...")
            if (paymentLink) {
                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    window.location.href = paymentLink;
                } else {
                    window.open(paymentLink, '_blank');
                }
            }
        }
    }, [paymentLink])

    return (
        <div className={`mainContainer kaspiQr`} style={{marginTop: 16, width: "100%"}}>
            {((qrToken || paymentLink) && isButtonClicked)? (
                    <div>
                        {paymentStatus != "Processed" && (
                            <div className={"goBack"} onClick={() => { setQrToken(''); setPaymentLink(''); setIsButtonClicked(false) }}>
                                <IconSvg src={left} className={'backBtn'} width='40px' height='40px' />
                                <div className={'display-xs'} style={{fontWeight: 600}}>Отмена</div>
                            </div>
                        )}

                        <div style={{ display: 'flex', maxHeight: "100%", paddingTop: "10px", justifyContent: 'center', alignItems: 'center' }}>
                            {(paymentStatus == "QrTokenCreated" && qrToken) && (
                                <div className="kaspi-item-wrapper">
                                    <div className="logo">
                                        <IconSvg src={kaspiQrScanAndPayRus} width='200px' height='84px' />
                                    </div>

                                    <div className="price">
                                        {amount? currencyFormat(amount, true) : 0}
                                    </div>

                                    <div className="kaspi-qr-box">
                                        <div className="qr-image"> 
                                            <div className={'qr-image'}>
                                                <QRCode size={200} value={qrToken} />
                                            </div>
                                        </div>
                                        <div className="kaspi-qr-logo">
                                            <IconSvg src={kaspiLogoForQr} width='42px' height='42px' />
                                        </div>
                                    </div>
                                    <div className="sub-title">
                                        <IconSvg src={kaspiPaymentMethodsRus} width='320px' height='20px' />
                                    </div>
                                    <div className={'payment-methods'}>
                                        <IconSvg src={kaspiGold} width='56px' height='44px' />
                                    </div>
                                </div>
                            )}
                            {(paymentStatus == "Wait" || (paymentStatus == "QrTokenCreated" && paymentLink)) && (
                                <div style={{ display: "grid" }}>
                                    <span className={'text-lg text-regular text-gray-500'}>Ожидание оплаты...</span>
                                </div>
                            )}
                            {paymentStatus == "Processed" && (
                                <div style={{ display: "grid", justifyItems: "center", justifyContent: "center", gap: "24px", padding: '40px 0' }}>
                                    <IconSvg src={successKaspi} width='110px' height='110px' color='white' />
                                    <span style={{ color: '#21A742' }} className={'text-lg text-medium'}>Оплата прошла успешно!</span>
                                </div>
                            )}
                            {paymentStatus == "Error" && (
                                <div style={{ display: "grid", justifyItems: "center", justifyContent: "center", gap: "24px" }}>
                                    <IconSvg src={errorKaspi} width='110px' height='110px' color='white' />
                                    <span style={{ color: '#D41900' }} className={'text-lg text-medium'}>{isTimeout ? "Время ожидания истекло" : "Оплата не прошла"}</span>
                                    <button onClick={() => {setQrToken(''); setPaymentLink(''); onKaspiPayment(); }} type='outline'>Вернуться на страницу Оплаты</button>
                                </div>
                            )}
                        </div>
                    </div>
                ) 
                : 
                (
                    <div>
                        <div className={"goBack"} onClick={onBack}>
                            <IconSvg src={left} className={"backBtn"} width='40px' height='40px' />
                            <div className={'display-xs text-semibold'}>Оплата</div>
                        </div>

                        <div className={"tariffContainer"}>
                            <div className={"paymentForm"}>
                                <div className={"paymentDetails"}>
                                    <div className={'text-md text-regular'}>
                                        <span>Подписка</span> 
                                        <div className={'text-md text-semibold'}>{rate}</div>
                                    </div>
                                    <div className={'text-md text-regular'}>
                                        <span>Цена</span> 
                                        <div className={'text-md text-semibold'}>{currencyFormat(amount)}</div>
                                    </div>
                                    <div className={'text-md text-regular'}>
                                        <span>Срок</span> 
                                        <div className={'text-md text-semibold'}>{term}</div>
                                    </div>
                                </div>

                                <div className={"separator"}></div>

                                <div className={"exampleForm"}>
                                    <KaspiButton isQr={!isMobile} className={"kaspiButton"} onClick={() => {onButtonClick(); onKaspiPayment()}}>Оплатить </KaspiButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default KaspiQRPayment;
